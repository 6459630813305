import styled from "styled-components";


// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  
  background-position: center;
  width: 100%;
  min-height: 0px;

`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
  
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
 
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  width: 100%;

 
`;



export const TextTitle = styled.p`
  color: white;
  font-size: 16px;
  line-height: 1.6;
  font-family: special;
                                                
`;

export const TextSubTitle = styled.p`
  color: white;
  font-size: 16px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: white;
  font-size: 16px;
  font-family: special;
  margin-top: 0px;
  margin-bottom: -10px;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;
